import "./UserList.css";
import React, { useEffect, useState } from "react";
import axios from "axios";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [editId, setEditId] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  // Функция получения пользователей
  const fetchUsers = async () => {
    try {
      const response = await axios.get("/api/user");
      setUsers(response.data);
    } catch (err) {
      setError("Ошибка при загрузке пользователей");
    } finally {
      setLoading(false);
    }
  };

  // Функция добавления пользователя
  const addUser = async () => {
    try {
      const response = await axios.post("/api/user", { name, password });
      setUsers([...users, response.data]);
      setName("");
      setPassword("");
    } catch (err) {
      setError("Ошибка при добавлении пользователя");
    }
  };

  // Функция удаления пользователя
  const deleteUser = async (id) => {
    const confirmDelete = window.confirm(
      "Вы действительно хотите удалить этого пользователя?"
    );
    if (confirmDelete) {
      try {
        await axios.delete(`/api/user/${id}`);
        setUsers(users.filter((user) => user.id !== id));
      } catch (err) {
        setError("Ошибка при удалении пользователя");
      }
    }
  };

  // Функция обновления пользователя
  const updateUser = async () => {
    try {
      const response = await axios.put("/api/user", {
        id: editId,
        name,
        password,
      });
      setUsers(
        users.map((user) => (user.id === editId ? response.data : user))
      );
      setName("");
      setPassword("");
      setEditId(null);
    } catch (err) {
      setError("Ошибка при обновлении пользователя");
    }
  };

  // Используем useEffect для вызова fetchUsers при монтировании компонента
  useEffect(() => {
    fetchUsers();
  }, []);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="userList">
      <h1>Управление учетными записями</h1>

      {/* Форма для добавления и редактирования пользователя */}
      <input
        type="text"
        placeholder="Имя"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <div className="password-container">
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Пароль"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <span
          className={`password-icon ${showPassword ? "active" : ""}`}
          onClick={() => setShowPassword((prev) => !prev)}
        ></span>
      </div>

      {editId ? (
        <button onClick={updateUser}>Обновить пользователя</button>
      ) : (
        <button onClick={addUser}>Добавить пользователя</button>
      )}

      {/* Таблица пользователей */}
      <table className="user-table">
        <caption>Список пользователей:</caption>
        <thead>
          <tr>
            <th>ID</th>
            <th>Роль</th>
            <th>Имя</th>
            <th>Пароль</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>Администратор</td>
              <td>{user.name}</td>
              <td>*****</td>
              <td>
                <button
                  onClick={() => {
                    setEditId(user.id);
                    setName(user.name);
                    setPassword(user.password);
                  }}
                >
                  Редактировать
                </button>
                <button onClick={() => deleteUser(user.id)}>Удалить</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserList;
