import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthenticationContext"; // Импортируем useAuth
import axios from "axios";
import UserList from "./UserList"; // потом удалить

const Authorization = () => {
  const navigate = useNavigate();
  const { login } = useAuth(); // Получаем функцию для логина
  const [error, setError] = useState(""); // Изменили на строку для ошибок
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const checkUserCredentials = async () => {
    if (!name || !password) {
      setError("Пожалуйста, введите имя пользователя и пароль.");
      return;
    }

    setError(""); // Сбрасываем ошибку перед новым запросом

    try {
      const response = await axios.post("/api/login", { name, password });

      // Обработка успешного ответа
      if (response.status === 200) {
        login(); // Устанавливаем статус аутентификации
        navigate("/userlist");
      }
    } catch (error) {
      let errorMessage = "Произошла ошибка. Попробуйте еще раз.";

      // Дополнительная обработка статусов ошибок
      if (error.response) {
        if (error.response.status === 404) {
          errorMessage = "Пользователь не найден!";
        } else if (error.response.status === 400) {
          errorMessage = "Неверный пароль!";
        }
      } else {
        console.error("Ошибка запроса:", error.message);
      }

      setError(errorMessage); // Устанавливаем сообщение об ошибке
    }
  };

  return (
    <div className="container">
      <h1>Войти в систему</h1>
      <input
        type="text"
        placeholder="Логин"
        value={name}
        onChange={(e) => {
          setName(e.target.value);
          setError(""); // Сбрасываем ошибку при изменении имени
        }}
      />
      <div className="password-container">
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Пароль"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setError(""); // Сбрасываем ошибку при изменении пароля
          }}
        />
        <span
          className={`password-icon ${showPassword ? "active" : ""}`}
          onClick={() => setShowPassword((prev) => !prev)}
        ></span>
      </div>
      <button onClick={checkUserCredentials}>Вход</button>
      {error && <p style={{ color: "red" }}>{error}</p>}{" "}
      {/* Выводим сообщение об ошибке */}
      <UserList />
    </div>
  );
};

export default Authorization;
