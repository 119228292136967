import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./AuthenticationContext"; // Импортируем провайдер
import Authorization from "./Authorization";
import UserList from "./UserList";
import ProtectedRoute from "./ProtectedRoute"; // Импортируем защищенный маршрут
import "./App.css";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Authorization />} />
          <Route
            path="/userlist"
            element={<ProtectedRoute component={UserList} />}
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
